import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { METASTORE_API_URL } from '../metastore-api.token';
import { extractArrayParams, extractObjectParams } from './extractors';
import {
  MetastoreMetadataRequestContext,
  MetastoreRequestResult,
  MetastoreSelectRequest,
  TableFullDto
} from './models';

export enum MetastoreDeleteStatus {
  Success,
  Failed
}

@Injectable()
export class MetastoreService {
  private readonly http = inject(HttpClient);

  private readonly metastoreApi = inject(METASTORE_API_URL);

  selectCount<T>(request: MetastoreSelectRequest) {
    return this.http.post<MetastoreRequestResult<T> & { TotalCount: number }>(
      `${this.metastoreApi}/1/select?count=true`,
      request
    );
  }

  select<T>(request: MetastoreSelectRequest) {
    return this.http
      .post<
        MetastoreRequestResult<T>
      >(`${this.metastoreApi}/1/select?count=false`, request)
      .pipe(map(({ Result }) => Result));
  }

  find<T>(request: MetastoreSelectRequest) {
    request.query.$limit = '@limit';
    request.query.$offset = '@offset';
    request.parameters = {
      ...request.parameters,
      ...{ '@limit': 1, '@offset': 0 }
    };
    return this.http
      .post<
        MetastoreRequestResult<T>
      >(`${this.metastoreApi}/1/select?count=false`, request)
      .pipe(map(({ Result }) => (Result && Result[0]) ?? null));
  }

  insert<T>(entityName: string, object: any) {
    const { obj, parameters } = extractObjectParams(object);
    return this.http
      .post<MetastoreRequestResult<T>>(`${this.metastoreApi}/1/insert`, {
        insert: {
          [entityName]: [obj]
        },
        parameters
      })
      .pipe(map(({ Result }) => Result[0]));
  }

  insertRange<T>(entityName: string, objects: any[]) {
    const { extracted, parameters } = extractArrayParams(objects);
    return this.http
      .post<MetastoreRequestResult<T>>(`${this.metastoreApi}/1/insert`, {
        insert: {
          [entityName]: extracted
        },
        parameters
      })
      .pipe(map(({ Result }) => Result));
  }

  update<T>(entityName: string, object: Partial<T>) {
    const { parameters, obj } = extractObjectParams(object);
    return this.http
      .post<MetastoreRequestResult<T>>(`${this.metastoreApi}/1/update`, {
        update: {
          [entityName]: [obj]
        },
        parameters
      })
      .pipe(map(({ Result }) => Result[0]));
  }

  updateRange<T>(entityName: string, objects: Partial<T>[]) {
    const { extracted, parameters } = extractArrayParams(objects);
    return this.http
      .post<MetastoreRequestResult<T>>(`${this.metastoreApi}/1/update`, {
        update: {
          [entityName]: extracted
        },
        parameters
      })
      .pipe(map(({ Result }) => Result));
  }

  delete(entityName: string, id: number) {
    return this.http.post<{ status: MetastoreDeleteStatus }>(
      `${this.metastoreApi}/1/delete`,
      {
        key: id,
        name: entityName
      }
    );
  }

  metadata(context: MetastoreMetadataRequestContext) {
    return this.http.post<TableFullDto[]>(
      `${this.metastoreApi}/metadata`,
      context
    );
  }

  tableMetadata(tableName: string, context: MetastoreMetadataRequestContext) {
    return this.http.post<TableFullDto>(
      `${this.metastoreApi}/metadata/${tableName}`,
      context
    );
  }
}
