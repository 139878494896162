import { InjectionToken, Provider } from '@angular/core';

export const LOOKUP_PROVIDER = new InjectionToken<string[]>('Lookup provider');

export const LOOKUP_PROVIDERS = [
  'UserType',
  'Gender',
  'CampaignSource',
  'BonusWallet',
  'Campaign',
  'Communication',
  'CommunicationProvider',
  'CommunicationChannel',
  'CommunicationType',
  'CommunicationActivityType',
  'CommunicationIdentifierStatus',
  'CouponGenerator',
  'CouponType',
  'CustomEvent',
  'IdentifierType',
  'Method',
  'Question',
  'Questionnaire',
  'TicketGenerator',
  'Unsubscribe',
  'UserStatus',
  'AppMetricaCabinet',
  'AppMetricaConnectionType',
  'LoyaltyCardLevel',
  'LoyaltyCardStatus',
  'LoyaltyCardType',
  'LoyaltyCity',
  'LoyaltyCurrency',
  'LoyaltyDeliveryType',
  'LoyaltyOrderStatus',
  'LoyaltyOrderType',
  'LoyaltyRegion',
  'CommunicationProviderType',
  'CommunicationTemplateType',
  'DocumentGroup',
  'DocumentStatus',
  'DocumentType',
  'LimitInterval',
  'Pixel',
  'Product',
  'ReceiptInCampaignStatus',
  'ReceiptStatus',
  'ReferralCodeSet',
  'ReferralGenerator'
];
export function provideLookupProviders(): Provider[] {
  return LOOKUP_PROVIDERS.map((provider) => ({
    provide: LOOKUP_PROVIDER,
    useValue: provider,
    multi: true
  }));
}
