import { map, Observable } from 'rxjs';
import { TableFullDto } from './models';

export const getTableMetaByPath = (
  tablesMetadata$: Observable<TableFullDto[]>,
  fromTable: string,
  path: string[]
) =>
  tablesMetadata$.pipe(
    map((metadata) => {
      let currentMeta = metadata.find((m) => m.Name === fromTable);
      if (currentMeta) {
        let accessingCollection = false;
        let accessingNavigation = false;
        for (const part of path) {
          const simpleColumnIndex = currentMeta.Columns.findIndex(
            (c) => c.Name === part
          );
          if (simpleColumnIndex >= 0) {
            return currentMeta.Columns[simpleColumnIndex].Type;
          }
          const collectionPropertyIndex = currentMeta.Collections?.findIndex(
            (r) => r.Name === part
          );
          if (
            currentMeta.Collections &&
            collectionPropertyIndex !== undefined &&
            collectionPropertyIndex >= 0
          ) {
            accessingCollection = true;
            const collectionTargetTable: string =
              currentMeta.Collections[collectionPropertyIndex].TargetTable;
            currentMeta = metadata.find(
              (m) => m.Name === collectionTargetTable
            );
            if (!currentMeta) {
              return null;
            }
            continue;
          }
          const navPropertyIndex = currentMeta.Properties?.findIndex(
            (r) => r.Name === part
          ) as number | undefined;
          if (
            currentMeta?.Properties &&
            navPropertyIndex !== undefined &&
            navPropertyIndex >= 0
          ) {
            accessingCollection = false;
            accessingNavigation = true;
            currentMeta = metadata.find(
              (m) =>
                m.Name ===
                (currentMeta?.Properties &&
                  currentMeta?.Properties[navPropertyIndex].TargetTable)
            );
            if (!currentMeta) {
              return null;
            }
            continue;
          }
          return null;
        }
        return { meta: currentMeta, accessingCollection, accessingNavigation };
      } else {
        return null;
      }
    })
  );
