import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef
} from '@angular/core';
import slice from 'lodash-es/slice';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import {
  dataTypeToOperator,
  existOptions,
  IN_QUANTITY,
  numberOperators,
  operatorToString
} from '../../lib/constants';
import { getTableMetaByPath } from '../../lib/metadata-utils';
import {
  DataType,
  LogicalOperator,
  MetastoreOperator,
  TableFullDto
} from '../../lib/models';
import {
  extractHavingFromExist,
  extractOperator,
  extractPropertyPath,
  extractWhereFromExist,
  firstKey,
  firstValue,
  isBinaryExpression,
  isGroupingExpression,
  pushToGroupingExpression
} from '../../lib/where-helpers';
import { LOOKUP_PROVIDER } from '../../lookup-provider.token';
import { BinaryExpressionComponent } from './binary-expression.component';

@Component({
  selector: 'knk-exist-expression[expression][entityName][metadataProvider]',
  templateUrl: 'exist-expression.component.html',
  styleUrls: ['exist-expression.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExistExpressionComponent implements OnDestroy {
  private readonly lookups = inject(LOOKUP_PROVIDER);

  private readonly cd = inject(ChangeDetectorRef);

  _expression$ = new BehaviorSubject<Record<string, any>>({});

  MetastoreOperator = MetastoreOperator;

  @Input() set expression(val: Record<string, any>) {
    this._expression$.next(val);
  }

  _entityName$ = new BehaviorSubject<string>('');

  @Input() set entityName(val: string) {
    this._entityName$.next(val);
  }

  @Input() metadataProvider!: Observable<TableFullDto[]>;

  @Input() pathFromRoot: string | undefined;

  @Input() afterControlTemplate:
    | TemplateRef<{ $implicit: BinaryExpressionComponent }>
    | undefined;

  @Input() beforeControlTemplate:
    | TemplateRef<{ $implicit: BinaryExpressionComponent }>
    | undefined;

  @Input() controlSelector:
    | ((
        comp: BinaryExpressionComponent,
        type: DataType | 'lookup'
      ) => TemplateRef<{ $implicit: BinaryExpressionComponent }> | undefined)
    | undefined;

  @Output() expressionChanged = new EventEmitter<Record<string, any>>();

  @Output() removed = new EventEmitter<void>();

  destroy$ = new Subject<void>();

  propertyPath$ = this._expression$.pipe(
    map((expression) => extractPropertyPath(expression))
  );

  get propertyPathSync() {
    return extractPropertyPath(this._expression$.getValue());
  }

  existOperator$ = this._expression$.pipe(
    map((expression) =>
      this.isInQuantitySync ? IN_QUANTITY : extractOperator(expression)
    )
  );

  get existOperatorSync() {
    return extractOperator(this._expression$.getValue()) as
      | MetastoreOperator.exist
      | MetastoreOperator.notExist;
  }

  get whereBodySync() {
    return extractWhereFromExist(this._expression$.getValue());
  }

  whereBody$ = this._expression$.pipe(
    map((expression) => extractWhereFromExist(expression))
  );

  get havingBodySync() {
    return extractHavingFromExist(this._expression$.getValue());
  }

  havingBody$ = this._expression$.pipe(
    map((expr) => extractHavingFromExist(expr))
  );

  isInQuantity$ = this._expression$.pipe(
    map(
      () =>
        this.havingBodySync &&
        this.havingBodySync['$count'] &&
        this.havingBodySync['$count']['Id']
    )
  );

  get isInQuantitySync() {
    return !!(
      this.havingBodySync &&
      this.havingBodySync['$count'] &&
      this.havingBodySync['$count']['Id']
    );
  }

  get whereParameterName() {
    return `${
      this.pathFromRoot ? `${this.pathFromRoot}:` : ''
    }${this.propertyPathSync.join('.')}:${this.existOperatorSync}:$where`;
  }

  inQuantityOperator$ = this.havingBody$.pipe(
    map(
      (expr) =>
        expr &&
        firstValue(expr) &&
        firstValue(firstValue(expr) as Record<string, unknown>) &&
        firstKey(
          firstValue(firstValue(expr) as Record<string, unknown>) as Record<
            string,
            unknown
          >
        )
    )
  );

  get inQuantityOperatorSync() {
    const body = this.havingBodySync;
    return (body &&
      firstValue(body) &&
      firstValue(firstValue(body) as Record<string, unknown>) &&
      firstKey(
        firstValue(firstValue(body) as Record<string, unknown>) as Record<
          string,
          unknown
        >
      )) as MetastoreOperator;
  }

  get inQuantityValueSync() {
    return (
      this.havingBodySync &&
      this.havingBodySync['$count'] &&
      this.havingBodySync['$count']['Id'] &&
      firstValue(this.havingBodySync['$count']['Id'])
    );
  }

  optionsByIndex$ = combineLatest([this.propertyPath$, this._entityName$]).pipe(
    takeUntil(this.destroy$),
    switchMap(([propertyPath, entityName]) =>
      (propertyPath.length
        ? combineLatest(
            propertyPath
              .map((_, index) => slice(propertyPath, 0, index))
              .map((slicedPath) =>
                getTableMetaByPath(
                  this.metadataProvider,
                  entityName,
                  slicedPath
                )
              )
          )
        : of([] as [])
      ).pipe(
        map((tableOrProperties) =>
          tableOrProperties.map((tableOrProperty) => {
            if (
              tableOrProperty === null ||
              typeof tableOrProperty === 'number'
            ) {
              return [];
            }
            const resultOptions = [
              {
                label: 'Columns',
                children: tableOrProperty.meta.Columns.map((c) => ({
                  value: c.Name,
                  label: c.Name
                }))
              }
            ];

            if (tableOrProperty.meta.Properties?.length) {
              resultOptions.push({
                label: 'Properties',
                children: tableOrProperty.meta.Properties?.map((r) => ({
                  value: r.Name,
                  label: r.Name
                }))
              });
            }

            if (tableOrProperty.meta.Collections?.length) {
              resultOptions.push({
                label: 'Collections',
                children: tableOrProperty.meta.Collections?.map((r) => ({
                  value: r.Name,
                  label: r.Name
                }))
              });
            }
            return resultOptions;
          })
        )
      )
    )
  );

  optionsOnEnd$ = combineLatest([this.propertyPath$, this._entityName$]).pipe(
    switchMap(([propertyPath, tableName]) =>
      getTableMetaByPath(this.metadataProvider, tableName, propertyPath).pipe(
        map((res) => ({ res, tableName }))
      )
    ),
    map(({ res, tableName }) => {
      if (res === null) {
        return [];
      }
      if (typeof res === 'number') {
        return [];
      }

      if (this.lookups.includes(res.meta.Name) && res.meta.Name !== tableName) {
        return [];
      }
      const columnsWithFkToLookup =
        res.meta.Properties?.filter((r) =>
          this.lookups.includes(r.TargetTable)
        )?.map((c) => c.BaseColumn) ?? [];

      const resultOptions = [
        {
          label: 'Columns',
          children: res.meta.Columns.filter(
            (c) => !columnsWithFkToLookup.includes(c.Name)
          ).map((c) => ({
            value: c.Name,
            label: c.Name
          }))
        }
      ];

      if (res.meta.Collections?.length) {
        resultOptions.push({
          label: 'Collections',
          children: res.meta.Collections?.map((r) => ({
            value: r.Name,
            label: r.Name
          }))
        });
      }

      if (res.meta.Properties?.length) {
        resultOptions.push({
          label: 'Properties',
          children: res.meta.Properties?.map((r) => ({
            value: r.Name,
            label: r.Name
          }))
        });
      }

      return resultOptions;
    })
  );

  operatorOptions$ = combineLatest([
    this.propertyPath$,
    this._entityName$
  ]).pipe(
    takeUntil(this.destroy$),
    switchMap(([propertyPath, entityName]) =>
      getTableMetaByPath(this.metadataProvider, entityName, propertyPath).pipe(
        map((res) => ({ res, tableName: this.entityName }))
      )
    ),
    map(({ res, tableName }) => {
      if (res === null) {
        return [];
      }
      if (typeof res === 'number') {
        return [
          {
            label: 'Operators',
            children: dataTypeToOperator[res].map((op) => ({
              value: op,
              label: operatorToString[op]
            }))
          }
        ];
      }
      if (res.accessingCollection) {
        return [
          {
            label: 'Operators',
            children: existOptions
          }
        ];
      }
      if (this.lookups.includes(res.meta.Name) && tableName !== res.meta.Name) {
        return [
          {
            label: 'Operators',
            children: [
              {
                value: MetastoreOperator.eq,
                label: operatorToString[MetastoreOperator.eq],
                isOperator: true
              },
              {
                value: MetastoreOperator.ne,
                label: operatorToString[MetastoreOperator.ne],
                isOperator: true
              }
            ]
          }
        ];
      }
      return [];
    })
  );

  currentNestedTable$ = combineLatest([
    this.propertyPath$,
    this._entityName$
  ]).pipe(
    switchMap(([path, tableName]) =>
      getTableMetaByPath(this.metadataProvider, tableName, path)
    ),
    map((res) => typeof res !== 'number' && res?.meta.Name)
  );

  inQuantityOptions = numberOperators.map((o) => ({
    value: o,
    label: operatorToString[o]
  }));

  updatePropertyOnIndex(part: string, index: number) {
    const newPath = [...this.propertyPathSync];
    newPath.splice(index);
    newPath.push(part);
    this.expressionChanged.emit({
      [newPath.join('.')]: {}
    });
  }

  addCondition() {
    if (!this.whereBodySync) {
      this.expressionChanged.emit({
        [this.propertyPathSync.join('.')]: {
          [this.existOperatorSync]: {
            $where: {},
            ...(this.havingBodySync ? { $having: this.havingBodySync } : {})
          }
        }
      });
      return;
    }

    if (isGroupingExpression(this.whereBodySync)) {
      this.expressionChanged.emit({
        [this.propertyPathSync.join('.')]: {
          [this.existOperatorSync]: {
            $where: pushToGroupingExpression(this.whereBodySync, {}),
            ...(this.havingBodySync ? { $having: this.havingBodySync } : {})
          }
        }
      });
      return;
    }

    this.expressionChanged.emit({
      [this.propertyPathSync.join('.')]: {
        [this.existOperatorSync]: {
          $where: {
            [LogicalOperator.and]: [this.whereBodySync, {}]
          },
          ...(this.havingBodySync ? { $having: this.havingBodySync } : {})
        }
      }
    });
  }

  addGroup() {
    if (isGroupingExpression(this.whereBodySync)) {
      this.expressionChanged.emit({
        [this.propertyPathSync.join('.')]: {
          [this.existOperatorSync]: {
            $where: pushToGroupingExpression(this.whereBodySync, {
              [LogicalOperator.and]: []
            }),
            ...(this.havingBodySync ? { $having: this.havingBodySync } : {})
          }
        }
      });
      return;
    }

    if (
      Object.values(Object.values(this._expression$.getValue())[0])[0] === true
    ) {
      this.expressionChanged.emit({
        [this.propertyPathSync.join('.')]: {
          [this.existOperatorSync]: {
            $where: {
              [LogicalOperator.and]: []
            },
            ...(this.havingBodySync ? { $having: this.havingBodySync } : {})
          }
        }
      });
      return;
    }

    if (isBinaryExpression(this.whereBodySync)) {
      this.expressionChanged.emit({
        [this.propertyPathSync.join('.')]: {
          [this.existOperatorSync]: {
            $where: {
              [LogicalOperator.and]: [
                this.whereBodySync,
                { [LogicalOperator.and]: [] }
              ]
            },
            ...(this.havingBodySync ? { $having: this.havingBodySync } : {})
          }
        }
      });
      return;
    }
  }

  pushToPropertyPathEnd(val: string) {
    this.expressionChanged.emit({
      [[...this.propertyPathSync, val].join('.')]: {
        [this.existOperatorSync]: {
          ...(this.whereBodySync ? { $where: this.whereBodySync } : {}),
          ...(this.havingBodySync ? { $having: this.havingBodySync } : {})
        }
      }
    });
  }

  remove() {
    this.removed.emit();
  }

  changeOperator(val: MetastoreOperator | typeof IN_QUANTITY) {
    if (val === IN_QUANTITY) {
      this.expressionChanged.emit({
        [this.propertyPathSync.join('.')]: {
          [MetastoreOperator.exist]: {
            ...(this.whereBodySync ? { $where: this.whereBodySync } : {}),
            ...(this.havingBodySync
              ? { $having: this.havingBodySync }
              : { $having: { $count: { Id: {} } } })
          }
        }
      });
    } else {
      if (this.whereBodySync) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [val]: {
              $where: this.whereBodySync
            }
          }
        });
      } else {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [val]: true
          }
        });
      }
    }
  }

  updateBody(expression: Record<string, any> | null) {
    if (!expression) {
      if (this.isInQuantitySync) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [this.existOperatorSync]: {
              $having: this.havingBodySync
            }
          }
        });
      } else {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [this.existOperatorSync]: true
          }
        });
      }
      return;
    }
    this.expressionChanged.emit({
      [this.propertyPathSync.join('.')]: {
        [this.existOperatorSync]: {
          $where: expression,
          ...(this.havingBodySync ? { $having: this.havingBodySync } : {})
        }
      }
    });
  }

  updateInQuantityOperator(operator: MetastoreOperator) {
    this.expressionChanged.emit({
      [this.propertyPathSync.join('.')]: {
        [this.existOperatorSync]: {
          ...(this.whereBodySync ? { $where: this.whereBodySync } : {}),
          $having: {
            $count: {
              Id: {
                [operator]: this.inQuantityValueSync
              }
            }
          }
        }
      }
    });
  }

  updateInQuantityValue(val: number) {
    if (!this.inQuantityOperatorSync) {
      return;
    }
    this.expressionChanged.emit({
      [this.propertyPathSync.join('.')]: {
        [this.existOperatorSync]: {
          ...(this.whereBodySync ? { $where: this.whereBodySync } : {}),
          $having: {
            $count: {
              Id: {
                [this.inQuantityOperatorSync]: val
              }
            }
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
