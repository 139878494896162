export interface Parameters {
  [key: string]: string | number | boolean;
}

export interface MetastoreSelectRequest {
  query: {
    $from: string;
    $select?: (string | { [key: string]: any })[];
    '$select $distinct'?: (string | { [key: string]: any })[];
    $where?: any;
    $limit?: string;
    $offset?: string;
    $groupBy?: string[];
    $orderBy?: (string | { [col: string]: 'desc' | 'asc' })[];
    $having?: any;
  };
  parameters?: Parameters;
}

export interface MetastoreInsertRequest<T> {
  insert: {
    [entity: string]: T[];
  };
  parameters: Parameters;
}

export interface MetastoreUpdateRequest<T extends { Id: number }> {
  update: {
    [entity: string]: T[];
  };
  parameters: Parameters;
}

export type MetastoreMetadataOperationType =
  | 'read'
  | 'update'
  | 'create'
  | 'delete';

export interface MetastoreMetadataRequestContext {
  ui?: { section?: string };
  operation?: { type?: MetastoreMetadataOperationType; name?: string };
}

export interface MetastoreRequestResult<T> {
  Result: T[];
}

// this should be moved to @konnektu/domain-models
export enum DataType {
  Int = 1,
  Long,
  String,
  DateTime,
  Guid,
  Bool,
  Json,
  Numeric,
  Double,
  Array,
  Enum
}

export interface Reference {
  Name: string;
  BaseColumn: string;
  TargetTable: string;
  TargetColumn: string;
}

export interface Column {
  Name: string;
  Type: DataType;
  Ordinal: number;
}

export interface TableDto {
  Name: string;
  IsUserDefined: boolean;
}

export interface TableFullDto extends TableDto {
  Columns: Column[];
  Properties?: Reference[];
  Collections?: Reference[];
}

export enum MetastoreOperator {
  eq = '$eq',
  ne = '$ne',
  gt = '$gt',
  gte = '$gte',
  lt = '$lt',
  lte = '$lte',
  exist = '$exist',
  notExist = '$not $exist',
  is = '$is',
  isNot = '$is $not',
  icContains = '$contains $ic',
  icNotContains = '$not $contains $ic',
  startsWith = '$starts $with',
  notStartsWith = '$not $starts $with'
}

export enum LogicalOperator {
  and = '$and',
  or = '$or'
}

export enum AggregationOperator {
  count = '$count',
  min = '$min',
  max = '$max',
  avg = '$avg',
  sum = '$sum'
}

export enum MetastoreQuerySource {
  Postgres = 1,
  Hive
}
